import { createSlice } from '@reduxjs/toolkit';
import { gsap } from 'gsap';
import { AUTOSAVE_TEMPLATE_SUCCESS } from '../actionTypeConstants';

const initialState = {
  selectedFormatId: null,
  selectedFormat: null,
  editorType: 'template',
  activeLayer: null,
  selectedLayers: [],
  campaignId: null,
  isEditingImage: false,
  isEditingOverlayImage: false,
  imageToEdit: null,
  menus: {
    rightMenu: '',
    leftMenu: {
      hoverActiveButton: '',
      activeButton: '',
      leftNavTabActive: '',
      dynamicLargeMenu: ''
    }
  },
  slate: {
    slateId: 0,
    editorId: 0
  },
  studio: {
    scale: 100,
    isLayersHidden: false,

    presentLayers: [],
    selectedFonts: [],

    timeline: window._zuuvi.makeTimeline(gsap.timeline()),
    timelineTime: 0,
    displayedTimelineTime: 0,

    animation: {
      isPaused: false,
      isPlaying: false,
      count: 0
    },

    activeAnimation: {
      uuid: null,
      target: null,
      index: null
    },

    iframeIsOpened: false
  },
  isTextEditable: null,
  isNestedTextEditable: null,
  dirtySaveState: false,
  clicktagPreviewData: [],
  clicktagErrorMessage: { clicktagId: '', message: '' },
  layersBelowClick: []
};

const editorSession = createSlice({
  name: 'editorSession',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(AUTOSAVE_TEMPLATE_SUCCESS, (state, action) => {
      state.dirtySaveState = false;
    });
    builder.addMatcher(
      (action) => 'meta' in action && action.meta.saveHistory,
      (state, action) => {
        state.dirtySaveState = true;
      }
    );
  },
  reducers: {
    selectFormat(state, action) {
      const format = action.payload;

      if (
        format &&
        (!state.selectedFormatId || !state.selectedFormat || state.selectedFormatId !== format.id)
      ) {
        // Only make an update if the selected format is different than the current one
        state.selectedFormat = format;
        state.selectedFormatId = format.id;
      }
    },
    setActiveLayer(state, action) {
      const layer = action.payload;
      if (state.activeLayer !== layer) {
        // Only make an update if the activeLayer is different than the current one
        state.activeLayer = layer;
      }
    },
    setEditorType(state, action) {
      state.editorType = action.payload;
    },
    setCampaignId(state, action) {
      state.campaignId = action.payload;
    },
    setSelectedLayers(state, action) {
      const layers = action.payload;
      state.selectedLayers = [...layers];
    },
    setIsEditingImage(state, action) {
      state.isEditingImage = action.payload;
    },
    setIsEditingOverlayImage(state, action) {
      state.isEditingOverlayImage = action.payload;
    },
    setImageToEdit(state, action) {
      state.imageToEdit = action.payload;
    },
    setRightMenu(state, action) {
      state.menus.rightMenu = action.payload;
    },
    resetRightMenu(state, action) {
      state.menus.rightMenu = initialState.menus.rightMenu;
    },
    setLeftMenu(state, action) {
      state.menus.leftMenu = action.payload;
    },
    resetLeftMenu(state, action) {
      state.menus.leftMenu = initialState.menus.leftMenu;
    },
    rerenderSlate(state, action) {
      state.slate.slateId += 1;
    },
    rerenderSlateEditor(state, action) {
      state.slate.editorId += 1;
    },
    setIsTextEditable(state, action) {
      state.isTextEditable = action.payload;
    },
    setIsNestedTextEditable(state, action) {
      state.isNestedTextEditable = action.payload;
    },
    setDirtySaveState(state, action) {
      state.dirtySaveState = action.payload;
    },
    setTimelineTime(state, action) {
      state.studio.timelineTime = action.payload;
      state.studio.displayedTimelineTime = action.payload;
    },
    setDisplayedTimelineTime(state, action) {
      state.studio.displayedTimelineTime = action.payload;
    },
    updateClicktagPreviewData(state, action) {
      const { clicktag, zuuviColor } = action.payload;
      let duplicatedPreviewArray = state.clicktagPreviewData;
      if (duplicatedPreviewArray.some((item) => item.clicktagId === clicktag.uuid)) {
        const clicktagIndex = duplicatedPreviewArray.findIndex(
          (item) => item.clicktagId === clicktag.uuid
        );
        duplicatedPreviewArray[clicktagIndex] = {
          clicktagId: clicktag.uuid,
          title: clicktag.title,
          start: parseFloat(clicktag.start_time).toFixed(1),
          stop: parseFloat(clicktag.stop_time).toFixed(1),
          duration: clicktag.stop_time - clicktag.start_time,
          color: zuuviColor
        };
      } else {
        duplicatedPreviewArray = [
          ...duplicatedPreviewArray,
          {
            clicktagId: clicktag.uuid,
            start: parseFloat(clicktag.start_time).toFixed(1),
            stop: parseFloat(clicktag.stop_time).toFixed(1),
            title: clicktag.title,
            duration: clicktag.stop_time - clicktag.start_time,
            color: zuuviColor
          }
        ];
      }
      state.clicktagPreviewData = duplicatedPreviewArray;
    },
    removePreviewedClicktagFromData(state, action) {
      const clicktagId = action.payload;
      const clicktagIndex = state.clicktagPreviewData.findIndex(
        (clicktag) => clicktag.clicktagId === clicktagId
      );
      // remove clicktag from previewedData
      state.clicktagPreviewData.splice(clicktagIndex, 1);
    },
    resetClicktagPreviewData(state, action) {
      state.clicktagPreviewData = [];
    },
    setClicktagErrorMessage(state, action) {
      const { clicktagId, message } = action.payload;
      state.clicktagErrorMessage = { clicktagId, message };
    },
    resetClicktagErrorMessage(state, action) {
      state.clicktagErrorMessage = { clicktagId: '', message: '' };
    },
    setScale(state, action) {
      state.studio.scale = action.payload;
    },
    playAnimations() {},
    stopAnimations() {},
    pauseAnimations() {},
    buildAnimations() {},
    addEventListenersToTimeline() {},

    toggleLayersHidden(state) {
      state.studio.isLayersHidden = !state.studio.isLayersHidden;
    },

    setPresentLayers(state, action) {
      state.studio.presentLayers = action.payload;
    },
    setActiveAnimation(state, action) {
      state.studio.activeAnimation = action.payload;
    },
    resetActiveAnimation(state) {
      state.studio.activeAnimation = initialState.studio.activeAnimation;
    },
    setSelectedFonts(state, action) {
      state.studio.selectedFonts = action.payload;
    },
    setAnimation(state, action) {
      state.studio.animation = action.payload;
    },
    setIframeIsOpened(state, action) {
      state.studio.iframeIsOpened = action.payload;
    },
    updateLayersBelowClick(state, action) {
      state.layersBelowClick = action.payload;
    }
  }
});

export const {
  selectFormat,
  setActiveLayer,
  setEditorType,
  setCampaignId,
  setSelectedLayers,
  setIsEditingImage,
  setIsEditingOverlayImage,
  setImageToEdit,
  setRightMenu,
  resetRightMenu,
  setLeftMenu,
  resetLeftMenu,
  rerenderSlate,
  rerenderSlateEditor,
  setIsTextEditable,
  setIsNestedTextEditable,
  setDirtySaveState,
  updateClicktagPreviewData,
  removePreviewedClicktagFromData,
  resetClicktagPreviewData,
  setClicktagErrorMessage,
  resetClicktagErrorMessage,
  setScale,
  toggleLayersHidden,
  setPresentLayers,
  setActiveAnimation,
  resetActiveAnimation,
  rerenderLayers,
  setSelectedFonts,
  setMoveEndHandleX,
  setAnimation,
  setTimelineTime,
  setDisplayedTimelineTime,
  playAnimations,
  stopAnimations,
  pauseAnimations,
  buildAnimations,
  setIframeIsOpened,
  updateLayersBelowClick,
  addEventListenersToTimeline
} = editorSession.actions;
export default editorSession.reducer;
