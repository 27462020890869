import { all, put, select, throttle, fork } from 'redux-saga/effects';
import * as api from '../../api';
import toast from 'react-hot-toast';
import * as Sentry from '@sentry/browser';
import { saveTemplate } from '../slices/template';

import templateSaga from './templateSaga';
import adSaga from './adSaga';
import creativeSetSaga from './creativeSetSaga';
import toastSaga from './toastSaga';
import imageSaga from './imageSaga';
import videoSaga from './videoSaga';
import userSettingsSaga from './userSettingsSaga';
import dashboardSaga from './dashboardSaga';
import dcoSaga from './dcoSaga';
import dpaSaga from './dpaSaga';
import previewSaga from './previewSaga';
import quickExportSaga from './quickExportSaga';
import automationSaga from './automationSaga';

import {
  AUTOSAVE_TEMPLATE_START,
  AUTOSAVE_TEMPLATE_SUCCESS,
  AUTOSAVE_TEMPLATE_ERROR
} from '../actionTypeConstants';
import { templateStoreRequest } from '../../api/templateStore';
import { triggerDpaUpdate } from '../../api/dpaService';

// Check all actions, to trigger autosave constantly.
export default function* rootSaga() {
  yield all([
    adSaga(),
    creativeSetSaga(),
    templateSaga(),
    imageSaga(),
    videoSaga(),
    toastSaga(),
    userSettingsSaga(),
    dashboardSaga(),
    dcoSaga(),
    dpaSaga(),
    previewSaga(),
    quickExportSaga(),
    automationSaga(),
    throttle(5000, saveTemplate, autosaveTemplate)
    // debounce(0, 'SAVE', autosaveTemplate),
    // debounce(1000, '*', autosaveTemplate)
  ]);
}

let autosaveIsEnabled = true;
function disableAutosave() {
  autosaveIsEnabled = false;
}
function enableAutosave() {
  autosaveIsEnabled = true;
}
window.addEventListener('mousedown', disableAutosave, { capture: false, passive: true });
window.addEventListener('mouseup', enableAutosave, { capture: false, passive: true });
window.addEventListener('drop', enableAutosave, { capture: false, passive: true });

function* autosaveTemplate(action) {
  const state = yield select();
  // TODO : Don't autosave banners (App workspace) for now. Maybe change it later?
  if (
    state.editorSession.editorType !== 'banner' &&
    state.autosave.saveState &&
    autosaveIsEnabled
  ) {
    yield put(autosaveTemplateStart());
    const templateState = JSON.parse(JSON.stringify(state.template));
    if (window._env_.REACT_ENV_GIT_COMMIT_HASH)
      templateState.object_data.release_hash = window._env_.REACT_ENV_GIT_COMMIT_HASH;
    // Remove useless data
    delete templateState._history;
    try {
      const response = yield api.templateStore.templateStoreRequest(
        `templates/${templateState.object_data.uuid}/save`,
        'POST',
        templateState
      );
      const template = yield response.json();

      yield put(autosaveTemplateSuccess(template));

      if (action.payload && action.payload.published) {
        try {
          yield templateStoreRequest(`templates/${template.id}/publish`, 'POST');

          yield fork(triggerDpaUpdate, {
            template_id: template.id
          });
        } catch (e) {
          toast.error('Could not publish template.', {
            id: 'template-published'
          });
        } finally {
          toast.success('Template published.', {
            id: 'template-published'
          });
        }
      }
    } catch (error) {
      toast.error('Error in saving template');
      Sentry.captureMessage('savingError');
      yield put(autosaveTemplateError(error));
    }
  }
}

function autosaveTemplateSuccess(template) {
  return {
    type: AUTOSAVE_TEMPLATE_SUCCESS,
    payload: {
      updated_at: template.updated_at
    }
  };
}
function autosaveTemplateError(error) {
  return {
    type: AUTOSAVE_TEMPLATE_ERROR
  };
}

function autosaveTemplateStart() {
  return {
    type: AUTOSAVE_TEMPLATE_START
  };
}
