/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import { select, call, put, fork, takeLatest } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import { getAd, saveAd, publishAd, setTemplate, resetUndoHistory } from '../slices/template';
import { setCurrentAccountId } from '../slices/auth';
import { setError } from '../slices/error';
import * as api from '../../api';
import { selectFormat, setCampaignId } from '../slices/editorSession';
import { loadUsedAssets, loadAssets } from './assetsHelper';

import { triggerDpaUpdate } from '../../api/dpaService';
import { fetchDcoFromTemplate, resetDcoSelection } from '../slices/dco';
// selectors need to be exported for testing
export const selectTemplate = (state) => state.template;
export const selectSelectedFormatId = (state) => state.editorSession.selectedFormatId;

/**
 * Get ad
 */
export function* handleGetAd(action) {
  const toastId = yield toast.loading('Loading banner ...', { id: 'load-ad' });
  try {
    const { data } = yield call(api.adService.getAd, action.payload);
    yield toast.dismiss(toastId);
    yield fork(handleGetAdSucceeded, data);
  } catch (e) {
    yield toast.error('Error loading banner.', {
      id: toastId
    });
    yield put(setError(e));
  }
}

export function* handleGetAdSucceeded(data) {
  const temporaryTemplate = data.template;
  const temporaryFormat = data.format;
  const template = {
    ...temporaryTemplate,
    formats: [temporaryFormat]
  };

  if ('all_assets' in data) {
    template.object_data.images = data.all_assets.images;
    template.object_data.videos = data.all_assets.videos;
    template.object_data.fonts = data.all_assets.fonts;
  }

  yield put(setCurrentAccountId(data.client_id));
  yield put(setCampaignId(data.campaign_id));
  yield put(setTemplate(template));
  yield put(selectFormat(temporaryFormat));
  yield put(resetUndoHistory());

  // Set DCO
  yield put(resetDcoSelection());
  if (
    template.object_data.dynamic &&
    (template.object_data.dynamic.dynamic_uuid ||
      template.object_data.dynamic.parameter_uuid ||
      ('custom' in template.object_data.dynamic &&
        'id' in template.object_data.dynamic.custom &&
        template.object_data.dynamic.custom.id))
  ) {
    yield put(fetchDcoFromTemplate());
  }

  // Load thumbnail images
  yield call(loadAssets);

  yield call(loadUsedAssets, template);
}

/**
 * Save ad
 */
export function* handleSaveAd(action) {
  const toastId = yield toast.loading('Saving banner ...', { id: 'save-ad' });

  const { adId } = action.payload;
  const template = yield select(selectTemplate);
  const selectedFormatId = yield select(selectSelectedFormatId);
  try {
    let format = null;
    for (const templateFormat of template.formats) {
      if (templateFormat.id === selectedFormatId) {
        format = templateFormat;
        break;
      }
    }
    const requestData = {
      format: format.object_data,
      template: template.object_data
    };
    const request = yield call(api.adService.saveAd, adId, requestData);

    if (request.status === 200) {
      yield fork(handleSaveAdSucceeded, toastId, action.payload.callback);
    } else {
      yield fork(handleSaveAdFailed, toastId, request, action.payload.failCallback);
    }
  } catch (e) {
    yield fork(handleSaveAdFailed, toastId, e, action.payload.failCallback);
  }
}

export function* handleSaveAdSucceeded(toastId, callback) {
  yield toast.success('Banner saved.', {
    id: toastId
  });
  if (callback) yield callback();
}

export function* handleSaveAdFailed(toastId, error, callback) {
  yield toast.error('Error saving banner.', {
    id: toastId
  });
  yield put(setError(error));
  if (callback) yield callback();
}

/**
 * Publish ad
 */
export function* handlePublishAd(action) {
  const toastId = yield toast.loading('Publishing banner ...', { id: 'publish-ad' });
  const { adId, callback } = action.payload;

  try {
    const request = yield call(api.adService.publishAd, adId);
    if (request.status === 200) {
      yield toast.success('Banner published.', {
        id: toastId
      });
      if (callback) yield action.payload.callback(request.data);
    } else {
      yield fork(handlePublishAdFailed, toastId, request, action.payload.failCallback);
    }
  } catch (e) {
    yield fork(handlePublishAdFailed, toastId, e, action.payload.failCallback);
  }
}

export function* handlePublishAdFailed(toastId, error, callback) {
  yield toast.error('Error publishing banner.', {
    id: toastId
  });
  yield put(setError(error));
  if (callback) yield callback();
}

function* watchAd() {
  yield takeLatest(getAd, handleGetAd);
  yield takeLatest(saveAd, handleSaveAd);
  yield takeLatest(publishAd, handlePublishAd);
}

export default watchAd;
